














































import { get_setting_shift, post_user_scheduling } from "@/api";
import { settingShiftType, settingUserDetailShiftType } from "@/api/api.types";
import { Notif } from "@/store";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component({ name: "user-shift" })
export default class UserShift extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!: string;

  @Prop({ required: true })
  readonly shifts!: settingUserDetailShiftType[] | null;

  /* Variables */

  shiftData = [] as settingShiftType["data"]["_embedded"]["shift"];

  loading = false;

  items = [] as string[];

  /* Computed */

  /* Methods */

  async fetchShift(): Promise<void> {
    this.loading = true;
    try {
      const resp = await get_setting_shift({
        is_available: 1,
        limit: -1,
      });
      this.shiftData = resp.data._embedded.shift;
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  @Emit("submit")
  async submit(): Promise<void> {
    try {
      await post_user_scheduling({
        userProfile: this.value,
        shifts: this.items.toString(),
      });
      Notif.notif_success("User schedule has been added.");
      this.close();
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  @Emit("input")
  close(): null {
    return null;
  }

  /* Life-cycle Methods */

  @Watch("value")
  onValueChange(now: string): void {
    if (now) {
      this.fetchShift();
      if (this.shifts) {
        this.items = this.shifts.map((t) => t.uuid);
      }
    }
  }
}
